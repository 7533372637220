.modal-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(11, 11, 11, 0.5);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	background-size: contain;
	background: url("/src/assets/modal/modal-background.png") no-repeat center center / contain;
	display: flex;
	justify-content: center;
	max-width: 700px;
	margin: 80px;
}

.modal-content {
	margin: 30px 70px 40px 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	overflow: hidden;
	font-size: 20px;
	font-weight: bold;
	max-width: 700px;
}
