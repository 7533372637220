.body-wrapper {
	background-image: url("/src/assets/body/background.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	overflow: hidden;
}

.links {
	color: #4E9DB3;
	font-size: 14px;
	text-decoration: underline;
	margin: 4px 4px;
	cursor: pointer;
}
