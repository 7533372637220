.container {
	background: var(--white);
	border: 1px solid var(--affirmationsBorder);
	background-size: 100% 100%;
	aspect-ratio: 641/100;
	max-width: 800px;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
}

.completed {
	background: var(--green);
	border: 1px solid var(--greenBorder);
	background-size: 100% 100%;
	aspect-ratio: 641/100;
	max-width: 800px;
}

.container__title {
	font-family: Poppins;
	color: var(--accent);
	font-size: 32px;
}

.container__text {
	color: var(--text);
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	box-sizing: border-box;
}
