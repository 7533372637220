.list-background {
	background: url("/src/assets/players-list/players-list-background.png") no-repeat center center / contain;
	background-size: contain;
	display: flex;
	flex-direction: column;
	width: 354px;
	height: 282px;
	align-items: center;
	justify-content: center;
	overflow: auto;
	padding: 28px;
	box-sizing: border-box;
}

.list-content::-webkit-scrollbar {
	width: 6px; /* width of the entire scrollbar */
}

.list-content::-webkit-scrollbar-thumb {
	background-color: #E0E0E0; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
}

.list-content::-webkit-scrollbar-track {
	background-color: transparent; /* color of the tracking area */
}

.list-content {
	width: 100%;
	height: 100%;
	overflow: auto;
}
