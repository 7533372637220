@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

html {
	height: 100vh;
	height: -webkit-fill-available;
}

body, #root {
	height: 100vh;
	height: -webkit-fill-available;
	max-height: 100vh;
	max-height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
}

body {
	--default-font-family: "Poppins", Arial, sans-serif;
	margin: 0;
	font-family: var(--default-font-family);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;

	--white: #FFFFFF;
	--primary: #46217E;
	--accent: #A93180;
	--davyGray: #575757;
	--disabledAccent: rgb(188, 158, 178);
	--completed: #E4FDE1;
	--green: linear-gradient(180deg, rgba(182, 241, 191, 0.2) 0%, rgba(181, 248, 231, 0.2) 100%), #FFFFFF;
	--greenBorder: #1ACA9F;
	--greenText: #498360;
	--text: #46217E;
	--affirmationsBorder: #EFCEE4;
}

* {
	font-family: var(--default-font-family);
}

h1, h2, h3, h4, h5, h6, p, span {
	color: var(--text);
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

#root {

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}


p {
	margin-block-start: 0;
	margin-block-end: 0;
}

:root {
	scrollbar-color:  rgba(188, 158, 178, 0.8) transparent;
}
::-webkit-scrollbar {
	background: transparent;
	width: 8px;
}
::-webkit-scrollbar-thumb {
	background: rgba(188, 158, 178, 0.8);
	border-radius: 5px;
}
