.container-vod {
	background: url("/src/assets/mito-card.png") no-repeat;
	background-size: contain;
	height: 205px;
	width: 300px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: default;
	transition: 0.3s all;
}


.verdad {
	background: url("/src/assets/verdad-card.png") no-repeat;
	background-size: contain;
}

.selected {
	height: calc(300px * 0.6859);
	width: 300px;
}

.not-selected {
	height: calc(260px * 0.6859);
	width: 260px;
	opacity: 0.7;
}

.card-text-vod {
	font-size: 42px;
	font-weight: 400;
	font-family: Lobster;
	color: white;
}

.card-result-vod {
	font-size: 20px;
	font-family: Lobster;
	color: var(--white);;
}

@media screen and (max-width: 591px) {
	.container-vod {
		background-size: 110%;
		background-position: center;
		border: 5px solid var(--white);
		border-top: 2px solid var(--white);;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
	}

	.verdad {
		border-top-left-radius: 24px;
		border-top-right-radius: 24px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		background-size: 110%;
		border: 5px solid var(--white);;
		border-bottom: 2px solid var(--white);;
	}

}

.add-border {
	background-size: 110%;
	background-position: center;
	border-radius: 24px;
	border: 5px solid var(--white);
}
