.lds-dual-ring {
	display: inline-block;
	width: 40px;
	height: 40px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 18px;
	height: 18px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid;
	border-color: var(--white) transparent var(--white) transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

.green:after {
	border-color: var(--primary) transparent var(--primary) transparent;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.accent:after {
	border-color: var(--primary) transparent var(--primary) transparent;
}
